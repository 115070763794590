body{
    font-family: Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important;
}


.reportTable .ant-table-row {
    height: 40px !important;
}

/* .reportTable .ant-table-thead > tr > th {
    padding: 10px 15px !important;
    font-size: 15px !important;
} */

.reportTable .ant-table-cell {
    padding: 0 10px !important;
    font-size : 13px !important;
}

/* Main table styles */
.reportTable .ant-table-thead > tr > th {
    padding: 10px 15px !important;
    font-size: 15px !important;
    background-color: #343a40; 
    color: #ffffff;
}

.reportGroupTable .ant-table-thead > tr > th {
    padding: 10px 15px !important;
    font-size: 14px !important;
    background-color: #3e484a  !important; 
    color: #ffffff !important;
}
  
  /* Reset styles for expanded tables */
.expandedTable .ant-table-thead > tr > th {
    padding: 7px !important; 
    font-size: 13.5px !important;
    background-color: #495057  !important; 
    color: #ffffff;
}

.ant-table-wrapper .ant-table-tbody >tr >th >.ant-table-wrapper:only-child .ant-table,
.ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-wrapper:only-child .ant-table,
.ant-table-wrapper .ant-table-tbody >tr >th >.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table, 
.ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table{
    margin-block: unset !important;
    padding: 5px !important;
}

/* .report-table-header {
    background-color: #e9ecef !important;
    color: #333 !important;
    font-weight: bold !important;
} */

.expanded-table-header {
    background-color: #cfc4c4   ;
    color: #343a40 ;
    font-weight: 500;
}

.report-expanded-table-header{
    background-color: #dbe1e8   ;
    color: #343a40  !important;
    font-weight: 500;
}

.batch-table-header {
    background-color: #fce4ec  ; 
    color: #343a40 ;
    font-weight: 500;
}

.ant-table-tbody > tr > td {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.custom-danger {
    background-color: red !important;
    border-color: red !important;
  }
  
  .anchor-link {
    color: #0091d1 !important;
    text-decoration: none; /* Optionally remove underline */
  }
  
    .filter-button{
        background-color: rgb(122 123 124) !important;
        border-color: rgb(122 123 124) !important;
    }


/* Target the icon specifically */
.filter-button .anticon svg{
    color: white !important; /* Change the icon color to white */
}

 .ant-table-wrapper .ant-table-tbody >tr >th >.ant-table-wrapper:only-child .ant-table, 
.ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-wrapper:only-child .ant-table,
.ant-table-wrapper .ant-table-tbody >tr >th >.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table,
.ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table{
  margin-inline: 32px 0px !important;
} 

/* .expandedTable .ant-table-thead > tr > th,
.expandedTable .ant-table-tbody > tr > td {
  /* padding: 10px;  */

.expandedTable .ant-table-thead {
  background-color: #f0f2f5; /* Light background for headers */
}

.expandedTable .ant-table-row {
  background-color: #fafafa; /* Subtle alternate row colors */
}

.expandedTableWrapper{
width: 100%;
overflow-x: auto;
white-space: nowrap;
}

.expandedTableWrapper .ant-table {
min-width: 800px; /* Adjust minimum width as needed */
}

/* Calendar Date Picker*/

.ant-picker-input > input::placeholder {
    font-weight: normal !important;
    color: #999 !important; /* Customize color here */
  }

  /* Target the second input element for the second date field */
  .ant-picker-input-group > input::placeholder {
    font-weight: normal !important;
    color: #999 !important; /* Customize color here */
  }

.custom-date-picker{
    width: 100%;
    font-family: 'Arial', sans-serif !important;
    border-color: #d9d9d9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
}

.ant-picker-dropdown {
    font-size: 13px !important;
  }

.ant-picker-dropdown .ant-picker-date-panel {
width: 250px !important;
}

.ant-picker-dropdown .ant-picker-cell {
padding: 5px 0px !important;
}

.ant-picker-calendar {
font-size: 12px !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
border-radius: 4px !important;
}

.dateRange .ant-input, .ant-select-selector, .ant-input-number, .ant-input-number-input{
    height: auto !important;
}