/* Common */

body {
    margin: 0;
    background-color: #fff;
    color: #0c0c0c;
    font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 12px;
}

.ant-col {
    font-size: 12px;
}

.component-container {
    padding: 5px 10px 25px 10px;
}

.ul-messageIndexes {
    position: absolute;
    left: 0;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 116px;
    overflow: auto;
    z-index: 1;
    background: #fff;
    color: #0c0c0c;
    border-top: 1px solid #ccc;
    border-radius: 5px;
    top: 0;
}

.ul-messageIndexes li {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.ul-suggestion-close {
    position: absolute;
    right: 16px;
    z-index: 9;
    background: #fff;
    top: 0;
    border: 0;
    font-size: large;
    padding: 0px 5px;
}

/* Form style */

.ant-form-item {
    margin-bottom: 8px;
}

.cus_lable label {
    color: #121313 !important;
}

.form-input-header {
    font-size: 12px;
    font-weight: bold;
}

.ant-input,
.ant-select-selector,
.ant-input-number,
.ant-input-number-input {
    box-shadow: none !important;
    background: #fff !important;
    border: 1px solid #ccc !important;
    height: 28px !important;
    color: #0c0c0c !important;
    font-size: 14px;
}

/* Button style */
.cus-btn-action {
    height: 28px;
    font-size: 11px;
    box-shadow: none !important;
    background: #fff !important;
    border: 1px solid #ccc;
    color: #0c0c0c !important;
}

.cus-btn-fixed-top {
    position: sticky;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    padding: 5px 0;
    top: 0;
    left: 0;
}

/* Table Style */
.ant-table-body {
    overflow: auto !important;
}

.cus-table table {
    background: #fff;
    font-size: 12px;
}

.cus-table table .ant-table-thead .ant-table-cell {
    background: #000 !important;
    color: #fff !important;
    border: 1px solid #ccc !important;
    /* padding: 5px; */
}

.cus-table .ant-table-thead>tr>th::before {
    background: transparent !important;
}

.cus-table table .ant-table-tbody .ant-table-cell {
    background: #fff !important;
    color: #0c0c0c !important;
    border: 1px solid #ccc !important;
    padding: 0 5px;
}

/* antd Drawer */
.ant-drawer-content {
    background-color: #fff !important;
}

.ant-drawer-header {
    background-color: #fff !important;
    border-top: 3px solid #ccc;
}

.ant-drawer-title,
.ant-drawer .anticon {
    color: #0c0c0c !important;
}

.ant-drawer-content-wrapper {
    width: 95vw !important;
    height: calc(100vh - 41px);
}

.ant-drawer-footer {
    background-color: #fff;
}

/* antd Form */
.ant-form-item .ant-form-item-label>label {
    color: #0c0c0c;
    font-size: 13px;
    /* border-left: 1px solid #d9d9d9; */
}

.cus-ant-select {
    width: 100% !important;
}

/* Card */
.cus-card {
    background-color: #fff !important;
    border: 1px solid #ccc;
    font-size: inherit;
}

.cus-card .ant-card-head {
    background: #fff;
    color: #0c0c0c;
    border: 1px solid #ddd;
    padding-left: 10px;
    min-height: 28px;
}

.cus-card-title-icon {
    font-size: 12px;
    margin-right: 10px;
}

.cus-card .ant-card-body {
    padding: 10px;
}

.ant-divider-dashed {
    margin: 13px 0;
    border: 1px solid #ccc;
}

.ant-checkbox-wrapper {
    color: #0c0c0c;
    font-size: 12px;
}

/* Placeholder */
.ant-select-selection-placeholder {
    color: #0c0c0c !important;
}

.ant-picker-input input::placeholder {
    color: #0c0c0c !important;
}

/* Metadata */
.custom-drawers {
    width: 40%;
    position: inherit;
    right: 0;
    top: 0;
    bottom: 0;
}

.ant-table-wrapper .ant-table-thead>tr>th {
    padding: 8px 16px !important;
    font-size: 12px !important;
    white-space: nowrap;
}

.ant-table-wrapper .ant-table-cell {
    padding: 0 5px !important;
    font-size: 12px !important;
}

.cus-card-flag .ant-card-body{
    padding-bottom: 0;
}
.cus-table-medium .ant-table-thead>tr>th {
    padding: 10px 16px !important;
}
.cus-table-medium .ant-table-cell {
    padding: 3px 16px !important;   
}

.outgoing_msg {
    overflow: hidden;
    margin: 26px 0 26px
}
  
.received_msg {
    display: inline-block;
    vertical-align: top;
    width: 92%
}

.sent_msg {
    float: right;
    width: 46%
  }
  
.sent_msg p {
    background: #05728f none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    padding: 5px 10px 5px 12px;
    width: 100%;
}
  
.received_with_msg p {
    background: #ebebeb none repeat scroll 0 0;
    border-radius: 3px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    width: 60%;
}

.time_date{
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0
  }