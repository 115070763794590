/* .ant-select-selection-placeholder {
    color: #fff !important;
}

.ant-picker-input input::placeholder {
    color: #fff !important;
} */
.ant-modal-body .ant-input,.ant-modal-body .ant-select-selector,.cus-antd-white{
    background: #fff !important;
    color: #000 !important;
}
.ant-modal-body .ant-form-item .ant-form-item-label>label {
    color: #000;
}

/*Common*/
.border-0{
    border: 0 !important;
}
.text-color-black{
    color:#000;
}
.ant-btn.ant-btn-icon-only,.ant-btn-default{
    color: #000 !important;
}
/* Primary Button Style */
.ant-btn-primary {
    background-color: #007bff !important;
    border-color: #007bff !important;
}
.ant-layout-sider-zero-width-trigger {
    top: 12px !important;
}
.cus-drawer-sm{
    width: 375px !important;
}
.custom-table .ant-table-header {
    background-color: #080808; /* Header background color */
    color: #000; /* Header text color */
}

.custom-table .ant-table-header th {
    background-color: #007bff; /* Header cell background color */
    color: #fff; /* Header cell text color */
}

.custom-table .ant-table-header th.sort-asc::after {
    border-color: transparent transparent #fff transparent; /* Ascending sort icon color */
}

.custom-table .ant-table-header th.sort-desc::after {
    border-color: transparent transparent #fff transparent; /* Descending sort icon color */
}

.custom-drawers {
    /* Add your custom styles for the Drawer here */
}










/* Custom table header background and text color */
.cus-antd-white .ant-table-thead > tr > th {
    background-color: #000; 
    color: #000; 
    text-align: center;
}

/* Custom table header background and text color */
.cus-antd-white .ant-table-thead > tr > th {
    background-color: #fff; 
    color: #000; 
    text-align: center;
    padding: 8px 16px; /* Reduce padding to decrease height */
}

/* Customize the sort icons */
.cus-antd-white .ant-table-column-sorter-up,
.cus-antd-white .ant-table-column-sorter-down {
    color: #bfbfbf; 
}

/* Hover effect for the header */
.cus-antd-white .ant-table-thead > tr > th:hover {
    background-color: #fff; 
}

/* Optional: Border color for header */
.cus-antd-white .ant-table-thead > tr > th {
    border-color: #fafafa;
}

/* Reduce padding for table body cells as well, if needed */
.cus-antd-white .ant-table-tbody > tr > td {
    padding: 8px 16px; /* Reduce padding to match the header */
}



/* Alternate row colors */
.cus-antd-white .ant-table-tbody > tr:nth-child(odd) {
    background-color: #f7f7f7; /* Color for odd rows */
}

.cus-antd-white .ant-table-tbody > tr:nth-child(even) {
    background-color: #ffffff; /* Color for even rows */
}

/* Optional: Hover effect for table rows */
.cus-antd-white .ant-table-tbody > tr:hover {
    background-color: #e0e0e0; /* Hover color */
}