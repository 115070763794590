.custom-collapse .ant-collapse-content-box {
    padding: 5px !important;
}
  
.custom-collapse .ant-collapse-header {
  font-weight: 500;
}

.custom-client-select .ant-select-selector{
  color:#1e1b1b !important;
  background-color :#ebebeb !important;
  border-radius: '8px' !important;
  border-color: #fff !important;
}

.ant-select .ant-select-arrow {
  color: #1e1b1b !important;
}

.custom-client-select {
  width: 100% !important; /* Ensure full width */
  margin-bottom: 10px !important; /* Margin below the dropdown */
}

/* Custom Client*/
:where(.css-dev-only-do-not-override-tpassh) .ant-select-single.ant-select-show-arrow .ant-select-selection-item, :where(.css-dev-only-do-not-override-tpassh).ant-select-single.ant-select-show-arrow .ant-select-selection-search, :where(.css-dev-only-do-not-override-tpassh).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
  font-weight: 600 !important;
}

  /* Custom Menu Styling */
.custom-nav-menu .ant-menu-item {
  color: #dcdcdc; /* Default menu item color */
  transition: background 0.3s ease, color 0.3s ease;
}

.custom-nav-menu .ant-menu-item:hover {
  background-color: rgb(70, 80, 100); /* Hover background color */
}

.custom-nav-menu .ant-menu-item-selected {
  background-color:   #8e9296 !important; /* Active menu item background */
  color: white; /* Active menu item text color */
  font-weight: bold; /* Emphasize the active item */
  border-radius: 4px; /* Add a subtle rounded effect */
  border-bottom: 2px solid #ffffff;
}

.batch-table table .ant-table-thead .ant-table-cell {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid #ccc !important;
  padding: 5px;
 }

 .batch-table table .ant-table-tbody .ant-table-cell {
  /* background: #fff !important; */
  /* color: #0c0c0c !important; */
  border: 1px solid #ccc !important;
  padding: 0 5px;
  font-size: 12px !important;
}  

.call-btn {
  background-color: #52c41a !important;
  border-color: #52c41a !important;
  margin-left: 4px;
  margin-bottom: 5px;
  color: #ffffff !important;
}

.project-btn {
  padding: 10px !important;
  /* justify-content: flex-start; */
  margin: 3px 0 !important;
  width: 100% !important;
  text-align: left !important;
  color:white !important;
  background-color:rgb(51, 61, 81) !important;
}

.project-btn-selected {
  color: black !important;
  background-color: rgb(209 209 209) !important;
  width: 100% !important;
  margin: 3px 0;
  padding: 10px !important;
  text-align: left !important;
  /* justify-content: flex-start; */
}

.ant-form-item .ant-form-item-explain-error {
  font-size: 12px;
  margin-bottom: 5px;
}

.add-project-context-menu{
  background-color : rgb(214 209 209) !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px !important;
  min-width: 120px !important;
  z-index: 9999 !important;
  font-size : 14px !important;
}

.even-row {
  background-color: rgb(42 30 30 / 12%) !important;
}

.odd-row {
background-color: #ffffff !important;
}

.manage-group-table .ant-table-thead .ant-table-cell {
  background: #4d5e62 !important;
  color: #fff !important;
  border: 1px solid #ccc !important;
  padding: 5px;
 }

 .manage-group-table .ant-table-tbody .ant-table-cell {
  background: #dedede !important; 
  color: #0c0c0c !important;
  border: 1px solid #ccc !important;
  padding: 0 5px;
  font-size: 12px !important;
}  

.custom-transfer .ant-transfer-list-header {
  border-bottom: 1.5px solid #776666; 
  font-weight: bold;
  text-align: center;
}

.custom-transfer .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1890ff;
  border-color: #1890ff; 
}
.custom-transfer .ant-transfer-list-content-item {
  color: #333;
  font-weight: 500;
}

.custom-transfer .ant-checkbox-inner {
  width: 16px; 
  height: 16px; 
  border-radius: 4px;
  border: 1px solid #4c4e4c; 
}

.custom-transfer .ant-input-outlined:hover{
  border-color: #776666 ;
}

.custom-transfer .ant-transfer-operation button {
  background-color: #b9b5b5 !important; /* Button background color */
  border: none; /* Remove button border */
  color: rgb(73 66 66) !important; /* Text color */
  font-weight: bold; 
  border-radius: 4px; 
  padding: 8px 16px; 
}

.custom-transfer .ant-transfer-operation button:hover {
  background-color: #8e8a8a !important;
  color: white !important;
}

.custom-transfer .ant-transfer .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled){
  background-color: white !important; 
  color: black !important;
}

.custom-transfer .ant-transfer-list-search .anticon-search {
  font-size: 16px !important; /* Ensures it overrides Ant Design's default */
  color: #8e8a8a !important; /* Custom color */
  margin-right: 8px !important; /* Spacing adjustment */
}

.project-form .unique-project-type .ant-select-selection-placeholder {
  font-weight: normal !important;
  color: #999 !important;
}

.project-form .unique-project-type .ant-select-selection-item{
  font-weight: normal !important;
}